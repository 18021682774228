import React from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";

import { BubblesStatics } from "../../components/BubblesStatics";
import Layouts from "../../components/Layouts";
import { FinancialSection } from "../../components/Screens/Home/FinancialSection";
import { LatestBlogArticles } from "../../components/Screens/Home/LatestBlogArticles";
import { LatestNewsArticle } from "../../components/Screens/Home/LatestNewsArticle";
import { ProductSection } from "../../components/Screens/Home/ProductSection";
import { Testmonials } from "../../components/Screens/Home/Testmonials";
import { WorkingSteps } from "../../components/Screens/Home/WorkingSteps";
import Seo from "../../components/Seo";
import { useLatestArabicArticles } from "../../hooks/useLatestArabicArticles";
// import RequestCardModal from "../../components/RequestCardModal";

const IndexArabicPage = ({ data }) => {
	const page = data.page;
	const {
		allWpPost: { edges },
	} = useLatestArabicArticles();
	// Wordpress Quotes
	const testmonialsQuotes = {
		quoteOne: {
			image: page.testomnialsHomeAr.imageQuotesAr.quoteOneImage,
			name: page.testomnialsHomeAr.imageQuotesAr.quoteOneName,
			title: page.testomnialsHomeAr.imageQuotesAr.quoteOne,
		},
		quoteTwo: {
			image: page.testomnialsHomeAr.imageQuotesAr.quoteTwoImage,
			name: page.testomnialsHomeAr.imageQuotesAr.quoteTwoName,
			title: page.testomnialsHomeAr.imageQuotesAr.quoteTwo,
		},
	};

	const productsCards = [
		{
			title: page.productsHomeAr.firstCardProductsAr.header,
			text: page.productsHomeAr.firstCardProductsAr.text,
			image: page.productsHomeAr.firstCardProductsAr.image,
			button: (
				<Link to="/ar/products" className="w-full h-full">
					<motion.button
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						whileHover={{ x: -10 }}
						transition={{ duration: 1, type: "spring", stiffness: 50 }}
						variants={{
							visible: { x: 0, opacity: 1 },
							hidden: { x: 100, opacity: 0 },
						}}
						className={
							"py-5 px-8 rounded-2xl bg-[#F6B904]  drop-shadow-2xl  flex items-center text-white font-bold mt-8"
						}
					>
						<span className="mr-4 md:mr-20 rtl:mr-0 rtl:ml-4  md:rtl:ml-20">
							إطلب تمويل دلوقتي
						</span>
						<div className="rtl:transform rtl:rotate-180">{Arrow}</div>
					</motion.button>
				</Link>
			),
		},
		// {
		// 	title: page.productsHomeAr.secondCardProductsAr.header,
		// 	text: page.productsHomeAr.secondCardProductsAr.text,
		// 	image: page.productsHomeAr.secondCardProductsAr.image,
		// 	comingSoon: true,
		// 	// button: <RequestCardModal lang="ar" />,
		// 	// buttonClassName: "bg-[#1E1E1E]",
		// },
	];

	const workingCards = {
		cardOne: {
			title: page.howItWorksAr.howitworkstepsAr.stepOneTitle,
			text: page.howItWorksAr.howitworkstepsAr.stepOneText,
		},
		cardTwo: {
			title: page.howItWorksAr.howitworkstepsAr.stepTwoTitle,
			text: page.howItWorksAr.howitworkstepsAr.stepTwoText,
		},
		cardThree: {
			title: page.howItWorksAr.howitworkstepsAr.stepThreeTitle,
			text: page.howItWorksAr.howitworkstepsAr.stepThreeText,
		},
		cardFour: {
			title: page.howItWorksAr.howitworkstepsAr.stepFourTitle,
			text: page.howItWorksAr.howitworkstepsAr.stepFourText,
		},
	};
	return (
		<>
			<Seo title="الصفحة الرئيسية" />
			<Layouts lang={"ar"} dir="rtl">
				<FinancialSection
					miniHeader={page.heroHomeAr.pageHeaderHomeAr.miniHeader}
					header={page.heroHomeAr.pageHeaderHomeAr.header}
					text={page.heroHomeAr.pageHeaderHomeAr.text}
					googleLink={page.heroHomeAr.googlePlayLink}
					appGalleryLink={page.heroHomeAr.appGalleryLink}
					slider={page.heroHomeAr.sliderAr}
					lang="ar"
				/>

				<ProductSection
					miniHeader={page.productsHomeAr.miniHeaderProductsAr}
					header={page.productsHomeAr.headerProductsAr}
					subHeader={page.productsHomeAr.textProductsAr}
					cards={productsCards}
				/>
				<WorkingSteps
					miniHeader={page.howItWorksAr.miniHeaderStepsAr}
					// header="أبلكيشن كاشات بيشتغل بشكل رقمي بالكامل من البداية للنهاية"
					header={page.howItWorksAr.headerStepsAr}
					// subHeader="قدم وخد التمويل وخلص كل حاجة من على تليفونك"
					subHeader={page.howItWorksAr.textStepsAr}
					cards={workingCards}
					image={page.howItWorksAr.howItWorksImageAr}
				/>
				<Testmonials
					miniHeader={page.testomnialsHomeAr.miniHeaderTestimonialsAr}
					header={
						<>
							قصص نجاح
							<br /> من عملاء كاشات
						</>
					}
					subHeader={page.testomnialsHomeAr.textTestimonialsAr}
					videoUrl={page.testomnialsHomeAr.videoUrlAr}
					image={page.testomnialsHomeAr.imageTestimonialsAr}
					quotes={testmonialsQuotes}
					testmonialsItems={page.testomnialsHomeAr.testimonialsQuotesAr}
					testmonialsHeader="تمويلات صغيرة, تأثير كبير!"
				/>
				{page.lastNewsBannerHomeAr.newar.miniHeader &&
					page.lastNewsBannerHomeAr.newAr.header &&
					page.lastNewsBannerHomeAr.newAr.text && (
						<LatestNewsArticle
							miniHeader={page.lastNewsBannerHomeAr.newar.miniHeader}
							header={page.lastNewsBannerHomeAr.newAr.header}
							text={page.lastNewsBannerHomeAr.newAr.text}
							buttonText="قراءة المزيد"
							link={page.lastNewsBannerHomeAr.newAr.link}
							image={page.lastNewsBannerHomeAr.newAr.image}
						/>
					)}
				{/* <LatestBlogArticles
					miniHeader="المدونة"
					header="أخر الأخبار علي المدونة"
					blogPosts={edges}
					lang="ar"
				/> */}
				<BubblesStatics kpis={page.kpisAr.kpisAr} lang="ar" />
			</Layouts>
		</>
	);
};
export default IndexArabicPage;
export const pageQu = graphql`
	query HomeArabic {
		page: wpPage(databaseId: { eq: 89 }) {
			title
			heroHomeAr {
				pageHeaderHomeAr {
					miniHeader
					header
					text
				}
				appGalleryLink
				googlePlayLink
				sliderAr {
					image: sliderImage {
						link
						date
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
				}
			}
			productsHomeAr {
				miniHeaderProductsAr
				headerProductsAr
				textProductsAr
				firstCardProductsAr {
					header
					text
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 400, placeholder: BLURRED)
							}
						}
					}
				}
				secondCardProductsAr {
					header
					text
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 224, placeholder: BLURRED)
							}
						}
					}
				}
			}
			howItWorksAr {
				miniHeaderStepsAr
				headerStepsAr
				textStepsAr
				howItWorksImageAr {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1024, placeholder: BLURRED)
						}
					}
				}
				howitworkstepsAr {
					stepOneTitle
					stepOneText
					stepTwoTitle
					stepTwoText
					stepThreeTitle
					stepThreeText
					stepFourTitle
					stepFourText
				}
			}
			testomnialsHomeAr {
				miniHeaderTestimonialsAr
				headerTestimonialsAr
				textTestimonialsAr
				videoUrlAr
				testimonialsQuotesAr {
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1024, placeholder: BLURRED)
							}
						}
					}
					quote
					text
					name
				}
				imageTestimonialsAr {
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1024, placeholder: BLURRED)
						}
					}
				}
				imageQuotesAr {
					quoteOne
					quoteOneName
					quoteOneImage {
						sourceUrl
					}
					quoteTwo
					quoteTwoName
					quoteTwoImage {
						sourceUrl
					}
				}
			}
			lastNewsBannerHomeAr {
				newar {
					miniHeader
					header
					text
					link
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1920, placeholder: BLURRED)
							}
						}
					}
				}
			}
			kpisAr {
				kpisAr {
					title
					downloads
					accountregisterd
					loanrequested
				}
			}
		}
	}
`;
const Arrow = (
	<svg
		width="7"
		height="12"
		viewBox="0 0 7 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1 1L6 6L1 11"
			stroke="white"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);
