import { useStaticQuery, graphql } from "gatsby";

export const useLatestArabicArticles = () => {
	const data = useStaticQuery(graphql`
		query LatestAraibcArticles {
			allWpPost(
				filter: { language: { code: { eq: AR }, slug: { ne: null } } }
			) {
				edges {
					node {
						id
						language {
							code
							locale
						}
						featuredImage {
							node {
								link
								date
								localFile {
									childImageSharp {
										gatsbyImageData(width: 1920, placeholder: BLURRED)
									}
								}
							}
						}
						tags {
							nodes {
								name
							}
						}
						date
						title
						articleAuthor {
							profilePicArticleAuthor {
								link
								date
								localFile {
									childImageSharp {
										gatsbyImageData(width: 120, placeholder: BLURRED)
									}
								}
							}
							authorName
							authorTitle
						}
						slug
						title
					}
				}
			}
		}
	`);
	return data;
};
